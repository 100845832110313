@import '../../../../scss/theme-bootstrap';

.carousel-formatter__wrapper {
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
  position: relative;
  width: 100%;
  .carousel-formatter__header {
    padding: 15px 0;
    p {
      line-height: inherit;
    }
  }
  .carousel-dots {
    text-align: center;
    margin: 0;
    position: relative;
    top: auto;
    &--color-black {
      .slick-dots {
        button {
          &::before {
            background-color: $color-gray-on-light;
          }
        }
        .slick-active {
          button {
            &::before {
              background-color: $color-core-black;
            }
          }
        }
      }
    }
    &--color-white {
      .slick-dots {
        button {
          &::before {
            background-color: $color-core-dark-gray;
          }
        }
        .slick-active {
          button {
            &::before {
              background-color: $color-white;
            }
          }
        }
      }
    }
  }
  &.dots-vertical-align--dots-under {
    .carousel-dots {
      .slick-dots {
        bottom: -40px;
      }
    }
  }
  .carousel__slide {
    @include breakpoint($landscape-up) {
      transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .carousel-content & {
      @include breakpoint($landscape-up) {
        transition: all 0.666s cubic-bezier(0.65, 0.05, 0.36, 1);
      }
    }
  }
  .carousel-formatter__container {
    &:not(.slick-initialized) {
      .carousel__slide {
        &:not(:first-child) {
          display: none;
        }
        &:first-child {
          .content-block--image {
            min-height: 300px;
          }
        }
      }
      .carousel-content & {
        .hero-block__wrapper {
          display: none;
        }
      }
    }
  }
  .slick-next-button,
  .slick-prev-button {
    &.slick-prev,
    &.slick-next {
      top: 60%;
      height: 20px;
      width: 20px;
      @include breakpoint($landscape-up) {
        width: 40px;
        height: 40px;
      }
      &::before {
        content: unset;
      }
      svg.icon {
        position: inherit;
        width: 20px;
        height: 14px;
        @include breakpoint($landscape-up) {
          width: 40px;
          height: 40px;
        }
      }
      &.slick-custom--caret-with-bg,
      &.slick-custom--caret-with-bg-white {
        height: 40px;
        width: 40px;
        margin-top: 10px;
        @include breakpoint($landscape-up) {
          width: 80px;
          height: 80px;
        }
        svg {
          width: 40px;
          height: 14px;
          @include breakpoint($landscape-up) {
            width: 80px;
          }
        }
      }
      &.slick-custom--caret-with-bg {
        background: $color-black;
        svg {
          fill: $color-white;
        }
      }
      &.slick-custom--caret-with-bg-white {
        background: $color-white;
        svg {
          fill: $color-black;
        }
      }
    }
    &.slick-prev {
      #{$ldirection}: 0;
      svg.icon {
        #{$ldirection}: 2px;
        transform: translateY(-50%) rotate(90deg);
        @include breakpoint($landscape-up) {
          #{$ldirection}: -2px;
        }
      }
      &.slick-custom--caret-with-bg,
      &.slick-custom--caret-with-bg-white {
        @include breakpoint($landscape-up) {
          #{$ldirection}: -20px;
        }
        svg.icon {
          #{$ldirection}: 0;
          @include breakpoint($landscape-up) {
            #{$ldirection}: -2px;
          }
        }
      }
    }
    &.slick-next {
      #{$rdirection}: 0;
      svg.icon {
        #{$rdirection}: 2px;
        transform: translateY(-50%) rotate(-90deg);
        @include breakpoint($landscape-up) {
          #{$rdirection}: -2px;
        }
      }
      &.slick-custom--caret-with-bg,
      &.slick-custom--caret-with-bg-white {
        @include breakpoint($landscape-up) {
          #{$rdirection}: -20px;
        }
        svg.icon {
          #{$rdirection}: 0;
          @include breakpoint($landscape-up) {
            #{$rdirection}: -2px;
          }
        }
      }
    }
  }
  .slick-disabled {
    visibility: hidden;
  }
  .slick-slider {
    margin-bottom: 0;
  }
  .slick-slide {
    img {
      transform: unset;
    }
  }
  .slick-dots {
    transition: all 0.333s cubic-bezier(0.65, 0.05, 0.36, 1);
    width: 100%;
    bottom: 8px;
    button {
      height: 1px;
      opacity: 1;
      &::before {
        content: '';
        height: 1px;
        width: 12px;
        opacity: 1;
        background: $color-gray-on-light;
      }
    }
    .slick-active {
      button {
        &::before {
          height: 2px;
          background-color: $color-core-black;
        }
      }
    }
    li {
      height: 12px;
      width: 12px;
      margin: 0 4px;
    }
  }
}
